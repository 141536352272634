import React from "react"
import { Link } from "gatsby"
import { Image } from "../VindiciaComponents"

const BlogList = ({ posts }) => {
  const filterCategories = val => {
    const v = val.map(c => c.blog_tags && c.blog_tags.slug)
    return v.join(" ")
  }
  return (
    <div
      className="uk-grid-column-medium uk-child-width-1-3@s uk-text-center"
      data-uk-grid
      data-uk-height-match="target: > div > .uk-card > .uk-card-body"
    >
      {posts.map((post, i) => (
        <div key={post.node.uid}>
          <Link
            to={`/blog/${post.node.uid}`}
            className="uk-display-block uk-card uk-card-default uk-card-small uk-link"
            data-author={post.node.data.author.uid}
            data-tags={filterCategories(post.node.data.categories)}
          >
            <div className="uk-card-media-top">
              {post.node.data.tile_image_for_blog_list__required_ && (
                <Image
                  src={post.node.data.tile_image_for_blog_list__required_.fixed.src}
                  srcSet={post.node.data.tile_image_for_blog_list__required_.fixed.srcSet}
                  alt={post.node.data.headline.text}
                />
              )}
            </div>
            <div className="uk-card-body uk-text-left">
              <p className="uk-card-title">{post.node.data.headline.text}</p>
            </div>
            <div className="uk-card-footer uk-text-left">
              <p className="cta-link">
                Read more <span uk-icon="icon: arrow-right"></span>
              </p>
            </div>
          </Link>
        </div>
      ))}
    </div>
  )
}

export default BlogList
