import React from "react"
import { Link } from "gatsby"

const BlogCategories = props => {
  const postNodes = props.categories.sort((a, b) => (a.uid > b.uid ? 1 : -1))

  return (
    <section className="uk-section">
      <div className="uk-container">
        <h2>
          Blog / {props.category && props.category.length ? props.category : "All categories"}
        </h2>
        <div className="uk-background-muted uk-margin-medium-top uk-padding-small">
          <p className="uk-display-inline uk-margin-right">Categories:</p>
          <button className="uk-position-relative uk-button-large uk-text-left" type="button">
            <span className="uk-position-right uk-position-small" data-uk-icon="chevron-down" />
            <span className="uk-h5 uk-margin-remove-vertical uk-padding-remove uk-margin-right">
              {props.category ? props.category : "All categories"}
            </span>
          </button>
          <div data-uk-dropdown="mode: click; pos: center; offset: -20">
            <ul className="uk-nav uk-dropdown-nav">
              <li className="uk-active">
                <Link to={`/blog`}>All</Link>
              </li>
              {postNodes.map(c => (
                <li key={c.uid}>
                  <Link
                    to={
                      c.uid.toLowerCase() !== "uncategorized" ? `/blog/category/${c.uid}` : `/blog`
                    }
                  >
                    {c.data.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlogCategories
